import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppComponent } from './components/app/app.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { LoginGuard } from '../core/guards/login.guard';
import { ROLE } from '../core/models/enum/role.enum';
import { DocSearchComponent } from './components/doc-search/doc-search.component';
import { SupportComponent } from './support/components/support/support.component';
import { HowtoComponent } from './howto/components/howto/howto.component';
import { FaqComponent } from './faq/components/faq/faq.component';
import { RequestsModule } from './requests/requests.module';


const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        ROLE.ADMIN,
        ROLE.APPROVER,
        ROLE.VERIFIER,
        ROLE.WRITER,
        ROLE.POST_OFFICER,
        ROLE.READER,
        ROLE.OFFICER
      ]
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'summary',
      },
      {
        path: 'request-create-documents',
        canActivate: [AuthGuard],
        data: {
          roles: [
            ROLE.ADMIN,
            ROLE.APPROVER,
            ROLE.VERIFIER,
            ROLE.WRITER,
            ROLE.POST_OFFICER,
          ]
        },
        loadChildren: () => import('./request-create-documents/request-create-documents.module').then(m => m.RequestCreateDocumentsModule)
      },
      {
        path: 'documents',
        canActivate: [AuthGuard],
        data: {
          roles: [
            ROLE.ADMIN,
            ROLE.APPROVER,
            ROLE.VERIFIER,
            ROLE.WRITER,
            ROLE.POST_OFFICER,
            ROLE.READER,
            ROLE.OFFICER
          ]
        },
        loadChildren: () => import('./documents/document.module').then(m => m.DocumentModule)
      },
      {
        path: 'minor-document',
        canActivate: [AuthGuard],
        data: {
          roles: [
            ROLE.ADMIN,
            ROLE.POST_OFFICER
          ],
          smallDocument: true
        },
        loadChildren: async () => (await import('./documents/document.module')).DocumentModule
      },
      {
        path: 'external-documents',
        canActivate: [AuthGuard],
        data: {
          roles: [
            ROLE.ADMIN,
            ROLE.APPROVER,
            ROLE.VERIFIER,
            ROLE.WRITER,
            ROLE.POST_OFFICER,
            ROLE.READER,
            ROLE.OFFICER
          ]
        },
        loadChildren: () => import('./external-documents/external-documents.module').then(m => m.ExternalDocumentsModule)
      },
      {
        path: 'hr-documents',
        canActivate: [AuthGuard],
        data: {
          roles: [
            ROLE.ADMIN,
            ROLE.APPROVER,
            ROLE.VERIFIER,
            ROLE.WRITER,
            ROLE.POST_OFFICER,
            ROLE.READER,
            ROLE.OFFICER
          ]
        },
        loadChildren: () => import('./hr-documents/hr-documents.module').then(m => m.HrDocumentsModule)
      },
      {
        path: 'gdnt-documents',
        canActivate: [AuthGuard],
        data: {
          roles: [
            ROLE.ADMIN,
            ROLE.APPROVER,
            ROLE.VERIFIER,
            ROLE.WRITER,
            ROLE.POST_OFFICER,
            ROLE.READER,
            ROLE.OFFICER
          ]
        },
        loadChildren: () => import('./gdnt-documents/gdnt-documents.module').then(m => m.GdntDocumentsModule)
      },
      {
        path: 'gdce-documents',
        canActivate: [AuthGuard],
        data: {
          roles: [
            ROLE.ADMIN,
            ROLE.APPROVER,
            ROLE.VERIFIER,
            ROLE.WRITER,
            ROLE.POST_OFFICER,
            ROLE.READER,
            ROLE.OFFICER
          ]
        },
        loadChildren: () => import('./gdce-documents/gdce-documents.module').then(m => m.GdceDocumentsModule)
      },
      {
        path: 'gdt-documents',
        canActivate: [AuthGuard],
        data: {
          roles: [
            ROLE.ADMIN,
            ROLE.APPROVER,
            ROLE.VERIFIER,
            ROLE.WRITER,
            ROLE.POST_OFFICER,
            ROLE.READER,
            ROLE.OFFICER
          ]
        },
        loadChildren: () => import('./gdt-documents/gdt-documents.module').then(m => m.GdtDocumentsModule)
      },
      // {
      //   path: 'chat',
      //   canActivate: [AuthGuard],
      //   data: {
      //     roles: [
      //       ROLE.ADMIN,
      //       ROLE.APPROVER,
      //       ROLE.VERIFIER,
      //       ROLE.WRITER,
      //       ROLE.POST_OFFICER
      //     ]
      //   },
      //   loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule)
      // },
      {
        path: 'chat',
        canActivate: [AuthGuard],
        data: {
          roles: [
            ROLE.ADMIN,
            ROLE.APPROVER,
            ROLE.VERIFIER,
            ROLE.WRITER,
            ROLE.POST_OFFICER
          ]
        },
        loadChildren: () => import('./test-chat/test-chat.module').then(m => m.TestChatModule)
      },
      {
        path: 'requests',
        canActivate: [AuthGuard],
        data: {
          roles: [
            ROLE.ADMIN,
            ROLE.APPROVER,
            ROLE.VERIFIER,
            ROLE.WRITER,
            ROLE.POST_OFFICER,
            ROLE.READER,
            ROLE.OFFICER
          ]
        },
        loadChildren: () => RequestsModule
      },
      {
        path: 'cabinets',
        canActivate: [AuthGuard],
        data: {
          roles: [
            ROLE.ADMIN,
            ROLE.APPROVER,
            ROLE.VERIFIER,
            ROLE.WRITER
          ]
        },
        loadChildren: () => import('./cabinet/cabinet.module').then(m => m.CabinetModule)
      },
      {
        path: 'boxes',
        canActivate: [AuthGuard],
        data: {
          roles: [
            ROLE.ADMIN,
            ROLE.APPROVER,
            ROLE.VERIFIER,
            ROLE.WRITER
          ]
        },
        loadChildren: () => import('./box/box.module').then(m => m.BoxModule)
      },
      {
        path: 'folders',
        canActivate: [AuthGuard],
        data: {
          roles: [
            ROLE.ADMIN,
            ROLE.APPROVER,
            ROLE.VERIFIER,
            ROLE.WRITER
          ]
        },
        loadChildren: () => import('./folder/folder.module').then(m => m.FolderModule)
      },
      {
        path: 'offices',
        canActivate: [AuthGuard],
        data: {
          roles: [
            ROLE.ADMIN
          ]
        },
        loadChildren: () => import('./office/office.module').then(m => m.OfficeModule)
      },
      {
        path: 'users',
        canActivate: [AuthGuard],
        data: {
          roles: [
            ROLE.ADMIN,
            ROLE.APPROVER,
            ROLE.VERIFIER,
            ROLE.WRITER,
            ROLE.POST_OFFICER,
            ROLE.OFFICER,
            ROLE.READER
          ]
        },
        loadChildren: () => import('./users/user.module').then(m => m.UserModule)
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        data: {
          roles: []
        },
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'about',
        canActivate: [AuthGuard],
        data: {
          roles: []
        },
        loadChildren: () => import('./about/about.module').then(m => m.AboutModule)
      },
      {
        path: 'reports',
        canActivate: [AuthGuard],
        data: {
          roles: [
            ROLE.ADMIN,
            ROLE.APPROVER,
            ROLE.VERIFIER,
            ROLE.WRITER,
            ROLE.POST_OFFICER
          ]
        },
        loadChildren: () => import('./reports/report.module').then(m => m.ReportModule)
      },
      {
        path: 'summary',
        canActivate: [AuthGuard],
        data: {
          roles: [
            ROLE.ADMIN,
            ROLE.APPROVER,
            ROLE.VERIFIER,
            ROLE.WRITER,
            ROLE.POST_OFFICER,
            ROLE.READER
          ]
        },
        loadChildren: () => import('./summary/summary.module').then(m => m.SummaryModule)
      },
      {
        path: 'expired_doc',
        canActivate: [AuthGuard],
        data: {
          roles: [
            ROLE.ADMIN,
            ROLE.APPROVER,
            ROLE.VERIFIER,
            ROLE.WRITER
          ]
        },
        loadChildren: () => import('./expired-doc-report/expired-doc-report.module').then(m => m.ExpiredReportModule)
      },
      {
        path: 'letter',
        canActivate: [AuthGuard],
        data: {
          roles: [
            ROLE.ADMIN,
            ROLE.APPROVER,
            ROLE.VERIFIER,
            ROLE.WRITER,
            ROLE.POST_OFFICER
          ]
        },
        loadChildren: () => import('./letter/letter.module').then(m => m.LetterModule)
      }
    ]
  },
  {
    path: 'sign-in',
    canActivate: [LoginGuard],
    loadChildren: () => import('./sign-in/sign-in.module').then(m => m.SignInModule),
    data: {
      title: 'Sign In'
    },
  },
  {
    path: 'policy',
    loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule),
    data: {
      title: 'Policy'
    },
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then(m => m.PrivacyModule),
    data: {
      title: 'Privacy'
    },
  },
  {
    path: 'recovery',
    canActivate: [LoginGuard],
    loadChildren: () => import('./recovery/recovery.module').then(m => m.RecoveryModule),
    data: {
      title: "Recovery"
    }
  },
  {
    path: 'public/search',
    component: DocSearchComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        loadChildren: () => import('./../routes/doc-overview/home/home.module').then(m => m.HomeModules)
      },
      {
        path: 'details',
        loadChildren: () => import('./../routes/doc-overview/details/document-detail.module').then(m => m.DocumentDetailModules)
      },
      {
        path: 'all',
        loadChildren: () => import('./doc-overview/list-all/list-all.module').then(m => m.ListAllDocumentsModules)
      }
    ]
  },
  {
    path: 'support/:supportId',
    component: SupportComponent
  },
  {
    path: 'howto/:howtoId',
    component: HowtoComponent
  },
  {
    path: 'page/:faqId',
    component: FaqComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
